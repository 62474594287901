/**
 * The default Promise gets overwritten, likely by an external script we load.
 *
 * this will make sure we revert those changes.
 *
 * Will need to further investigate
 */

// To run after zone.js polyfill
global['_zoneJsPromise'] = global.Promise;

Object.defineProperty(global, 'Promise', {
    get: function () {
        return global['_zoneJsPromise'];
    },
    set: function (newPromise) {
        if (newPromise !== this._zoneJsPromise) {
            setTimeout(() => {
                global.Promise = global['_zoneJsPromise'];
            }, 250);
        }
    },
});
